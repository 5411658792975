.ag-table__compare-cell {
  display: flex;
  flex-direction: column;
  //align-items: flex-end;
  flex-grow: 1;
}

.ag-table__compare-cell-value {
  color: #333;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  text-align: end;
}

.ag-table__compare-cell-compare {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding-top: 5px;
  color: #aaa;
  font-weight: 600;
  font-size: 8px;
  line-height: 9.68px;

  p {
    display: flex;
    flex-flow: nowrap;
    flex-grow: 1;
    align-items: flex-end;
    justify-content: flex-end;

    span:last-child {
      margin-left: 2px;
    }

    text:last-child {
      margin-left: 2px;
    }
  }

  p:last-child {
    margin-left: 3px;
  }
}

.ag-cell.red {
  background-color: #ffe6e6;

  .ag-table__compare-cell-compare {
    color: #ff3131;
  }
}

.ag-cell.green {
  background-color: #e5f5ec;

  .ag-table__compare-cell-compare {
    color: #29af62;
  }
}
