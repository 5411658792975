.legend-btn {
  padding: 4px 8px;
  color: #333;
  font-weight: 400;
  font-size: 10px;
  font-family: Inter, sans-serif;
  font-style: normal;
  line-height: 16px;
  border-radius: 4px;

  &.active {
    background-color: #f5f5f5;
  }
}

.legend-title {
  display: inline-flex;
  align-items: center;
  width: 100%;

  .title {
    color: #333;
    font-weight: 600;
    font-size: 12px;
  }

  button {
    display: inline-flex;
    gap: 4px;
    align-items: center;
    margin-right: 0;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;

    svg path {
      fill: #00aeef;
    }
  }

  button:last-child {
    margin-left: 0;
  }
}
