.export-table__btn{
  position: absolute;
  right: 35px;
  margin-top: -32px;

  svg path{
    fill: #aaaaaa;
  }
  &:hover{
    svg path {
      fill: #333333;
    }
  }
}