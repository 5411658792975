.ag-cell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #333;
  font-size: 11px;
  font-family: Inter, serif;
  line-height: 14px;

  &.ag-cell__content-left {
    justify-content: flex-start;
  }
}

.ag-header-cell-label {
  width: max-content;
}

.ag-cell-wrapper {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table__container {
  height: 50vh;
}

.ag-row {
  & > .ag-cell {
    border-right: 1px solid #e9eaee;
  }

  //& > .ag-cell:last-child {
  //  border: none;
  //}
}

.ag-cell-label-container {
  gap: 8px;
}

.ag-header {
  background: #f7f7f7;
}
//.ag-menu{
//  position: absolute;
//}
.ag-root-wrapper {
  border: 1px solid #ededed;
  border-radius: 0;
}

//.ag-center-cols-container,
//.ag-center-cols-viewport {
//  min-height: min-content !important;
//}
//
//.ag-theme-quartz {
//  &.empty {
//    .ag-center-cols-container,
//    .ag-center-cols-viewport {
//      min-height: 72px !important;
//    }
//  }
//}
.ag-layout-auto-height {
  min-height: 200px;
}

.ag-header-cell {
  color: #aaa;
  font-weight: 600;
  font-size: 11px;
  font-family: 'Inter', serif;
  line-height: 16px;
  white-space: nowrap;
  text-align: left;
}

.ag-header-cell-resize::after {
  top: 0;
  width: 1px;
  height: 100%;
  margin-left: 1px;
  background-color: #e9eaee;
}
