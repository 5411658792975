.column-tool-panel__btn.main-button {
  position: absolute;
  right: 0;
  width: 32px;
  height: 32px;
  margin-top: -40px;
  padding: 8px;
  background-color: transparent;
  border-radius: 50%;

  &.secondary:not(:disabled):hover {
    background: transparent;

    svg path {
      fill: #333;
    }
  }

  &.show {
    background-color: #f5f5f5;

    svg path {
      fill: #333;
    }
  }
}

.column-tool-panel__items-wrapper {
  position: absolute;
  right: 0;
  z-index: 10;
  width: 180px;
  max-height: 217px;
  padding: 8px 9px;
  background-color: #fff;
  border: 1px solid var(--Main-Light-Gray, #ededed);
  border-radius: 3px;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.04);

  & > div {
    max-height: 201px;
    overflow-y: auto;
  }
}

.column-tool-panel__items-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.column-tool-panel__item-row {
  display: inline-flex;
  gap: 8px;
  align-items: center;
  height: 20px;

  /* Body 11pt Regular */
}

.column-tool-panel__item-text {
  max-width: 142px;
  overflow: hidden;
  font-weight: 400;
  font-size: 11px;
  font-family: Inter, sans-serif;
  font-style: normal;
  line-height: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
