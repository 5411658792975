.project-container {
  overflow-x: hidden;
}

.network-objects {
  width: 200px;
  padding-top: 16px;
  overflow: auto;
  background-color: #fff;
}

.project-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  background-color: #fff;
  border-bottom: 1px solid #ededed;
}

.project-header__menu {
  position: relative;
  margin-left: 8px;
}

.project-header__menu-icon-wrapper {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-right: 4px;
  border-radius: 50%;

  &:hover {
    & .project-header__menu-icon path {
      fill: #666;
    }
  }

  &.active {
    background: #f5f5f5;

    & .project-header__menu-icon path {
      fill: #333;
    }

    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #f5f5f5;
    }
  }
}

.project-header__menu-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.project-header__menu-list {
  position: absolute;
  z-index: 10;
  width: 237px;
  margin-top: 8px;
  padding: 8px 0;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.16);

  &.experiments-list {
    width: unset;
    height: unset;
  }
}

.project-header__menu-list-item {
  width: 100%;
  color: #333;
  font-weight: normal;
  font-size: 11px;
  line-height: 32px;
  text-align: left;
  text-indent: 16px;

  &:not(:disabled):hover {
    background-color: #f7f7f7;
  }

  &:disabled {
    color: #aaa;
    cursor: default;

    &:hover .project-header__icon-delete path {
      fill: #aaa;
    }
  }
}

.project-header__menu-list-item-delete {
  display: flex;
  align-items: center;

  &:hover .project-header__icon-delete path {
    fill: #666;
  }
}

.project-header__icon-delete {
  width: 16px;
  height: 16px;
  margin: -2px 8px 0 16px;
}

.project-header__menu-list-separator {
  width: 100%;
  height: 8px;
  margin-top: 8px;
  border-top: 1px solid #ededed;
}

.project-header__title {
  display: inline-block;
  color: #333;
  font-weight: 600;
  font-size: 12px;
  line-height: 32px;
}

.project-header__control-panel {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.project-header__experiment-select-wrapper {
  position: relative;
  margin-right: 8px;
}

.project-header__experiment-select-btn {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 164px;
  height: 32px;
  padding: 8px 8px 8px 12px;
  color: #333;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 16px;

  &:disabled {
    background-color: white;
    border: 1px solid #ededed;
  }

  &:focus {
    border: 1px solid #ddd;
  }

  &:hover {
    border: 1px solid #ededed;

    &:focus {
      border: 1px solid #ddd;
    }
  }
}

.project-header__experiment-select-btn-arrow-icon.disabled path {
  fill: #aaa;
}

.draw-panel {
  position: absolute;
  right: 311px;
  bottom: 0;
  left: 201px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  height: 44px;
  background: white;
  border-top: 1px solid #ededed;
  border-right: 1px solid #ededed;

  .draw-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-width: 50px;
    height: 36px;
  }

  .draw-button-text {
    color: #aaa;
    font-size: 10px;
  }

  .draw-button-text__active {
    color: #00aeef;
    font-size: 10px;
  }

  .draw-icon path {
    fill: #aaa;
  }

  .draw-button__active {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-width: 50px;
    height: 36px;

    .draw-icon path {
      fill: #00aeef;
    }
  }

  .draw-button:hover {
    .draw-button-text {
      color: #333;
      font-size: 10px;
    }

    .draw-icon path {
      fill: #333;
    }
  }
}

.draw-panel__label {
  margin: 0 0 0 24px;
  color: #aaa;
  font-weight: 500;
  font-size: 11px;
  font-style: normal;
  line-height: 16px;
  text-align: center;
}

.control-panel__icon-button {
  position: relative;
  width: 32px;
  height: 32px;
  background-color: #f5f5f5;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;

  &.results {
    display: flex;
    align-items: center;
    width: 28px;
    height: 28px;
    margin-right: 19px;
    background-color: transparent;

    &:not(.view) .results-icon:hover path {
      fill: #333;
    }

    &.view {
      background-color: #815df6;

      & .results-icon path {
        fill: white;
      }
    }

    &::after {
      position: absolute;
      top: 6px;
      right: -10px;
      width: 1px;
      height: 16px;
      background-color: #ededed;
      content: '';
    }
  }

  & .settings-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  & .results-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  & .new-results-icon {
    position: absolute;
    top: 2px;
    right: 2px;

    &.error circle {
      fill: #ff3131;
    }

    &.not-actual circle {
      fill: #fa8700;
    }
  }

  &.active-objects {
    background-color: transparent;

    .settings-icon:not(:hover) path {
      fill: #aaa;
    }
  }

  &.close-button {
    background-image: url(../../images/Project/close-icon.svg);
  }
}

.control-panel__progress-details {
  position: relative;
  height: 32px;
  margin-right: 19px;
  padding: 6px 61px 6px 12px;
  color: #00aeef;
  font-weight: 600;
  font-size: 10px;
  font-style: normal;
  line-height: 16px;
  background-color: #ecf8fd;
  border: 2px solid #ecf8fd;
  border-radius: 32px;

  &.solving {
    padding: 1.5px 61px 4.5px 15px;
    font-size: 8px;

    p {
      color: #9cbeca;
      line-height: 9.68px;
    }
  }

  &.without-stop-btn {
    padding: 6px 28px 6px 12px;

    & .control-panel__details-button-progress-icon {
      right: 6px;
    }
  }

  &.error {
    padding: 6px 12px 4px 12px;
    color: #ff3131;
    background-color: #ffe6e6;
    border-color: #ffe6e6;
  }

  &.not-actual {
    margin-left: 6px;
    padding: 6px 12px 4px 12px;
    color: #fa8700;
    background-color: #feecd6;
    border-color: #feecd6;
  }

  &.with-status {
    margin-left: 18px;
  }

  &:not(.without-stop-btn):not(.not-actual)::before {
    position: absolute;
    top: 6px;
    right: 32px;
    width: 1px;
    height: 16px;
    background-color: #ededed;
    content: '';
  }

  &:not(.with-status):not(.not-actual)::after {
    position: absolute;
    top: 6px;
    right: -8px;
    width: 1px;
    height: 16px;
    background-color: #ededed;
    content: '';
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.control-panel__details-button-progress-icon {
  position: absolute;
  top: 6px;
  right: 41px;
  width: 16px;
  height: 16px;
  animation: spin 4s linear infinite;

  &.solving {
    top: 0;
  }
}

.control-panel__loader {
  position: absolute;
  top: 6px;
  right: 41px;
  width: 16px;
  height: 16px;

  &.solving {
    top: 6px;
  }

  &.interrupting {
    right: 10px;
  }
}

.control-panel__details-button-cancel-icon {
  position: absolute;
  top: 6px;
  right: 8px;
  width: 16px;
  height: 16px;
  cursor: pointer;

  &:hover path,
  &:active path {
    fill: #333;
  }
}

.control-panel__details-button-error-icon {
  position: absolute;
  top: 7px;
  right: 8px;
  width: 16px;
  height: 16px;
}

.control-panel__progress-details-message {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 4px;
}

.network-objects__hover-btn {
  position: absolute;
  right: 0;
  width: 24px;
  height: 24px;
  opacity: 0;
  transition: all 0.25s ease;

  &.active {
    background-color: #f5f5f5;
    border-radius: 50%;
    opacity: 1;
  }

  &:not(.active):hover .button-icon path {
    fill: #666;
  }

  & svg {
    margin-right: 8px;
    vertical-align: middle;
  }
}

.network-object__hover-btn {
  position: absolute;
  right: 8px;
  width: 12px;
  height: 12px;
  opacity: 0;
  transition: all 0.25s ease;

  &:hover .button-icon path {
    fill: #666;
  }

  & svg {
    vertical-align: middle;
  }
}

.network-objects__title-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 26px;
  padding: 0 8px;
  color: #333;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  border: 1px solid #fff;
  border-radius: 4px;
  outline: none;
  transition: all 0.25s ease;

  &:hover {
    border: 1px solid #ededed;

    & .network-objects__hover-btn {
      opacity: 1;
    }
  }
}

.network-objects__title {
  display: flex;
  align-items: center;
}

.network-objects__sub-title {
  position: relative;
  display: block;
  box-sizing: border-box;
  width: calc(100% - 16px);
  margin: 0 8px;
  padding-left: 20px;
  color: #333;
  font-weight: normal;
  font-size: 11px;
  line-height: 26px;
  text-align: left;
  border: 1px solid #fff;
  border-radius: 4px;
  transition: all 0.25s ease;

  &:not(.active):hover {
    border: 1px solid #ededed;
  }

  &:hover {
    & .network-object__hover-btn:not(.all-objects) {
      opacity: 1;
    }
  }

  &.active {
    background: #f7f7f7;
    border: 1px solid #f7f7f7;
  }
}

.network-objects__icon {
  margin-right: 8px;

  &.button-icon {
    margin-right: 0;
  }

  &.button-icon.active path {
    fill: #333;
  }
}

.network-objects__row {
  padding: 0 8px;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
}

.project-task-settings__right-col {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 312px;
  max-height: calc(100vh - 56px);
  padding: 20px 0 0 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  border-left: 1px solid #ededed;

  & .main-button {
    margin-bottom: 20px;
  }
}

.project-task-settings__title {
  margin-bottom: 16px;
  color: #333;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;

  &.constraints-title {
    margin-top: 24px;
  }
}

.project-task-settings__sub-title {
  margin-bottom: 2px;
  color: #aaa;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
}

.project-task-settings__hint {
  min-height: 32px;
  margin-bottom: 4px;
  color: #ff3131;
  font-weight: 500;
  font-size: 10px;
  font-style: normal;
  line-height: 16px;
}

.project-task-settings__post-settings-hint {
  margin: 12px 0 24px 0;
  color: #aaa;
  font-weight: 500;
  font-size: 10px;
  font-style: normal;
  line-height: 16px;
}

.project-task-settings__cancel-btn {
  width: 147px;
  margin-left: 6px;
}

.project-task-settings__radio-buttons-container {
  display: flex;
  margin-bottom: 12px;
  padding: 2px;
  background: #f5f5f5;
  border-radius: 4px;
}

.project-task-settings__radio-button {
  input {
    display: none;

    &:checked + span {
      background: #fff;
      border-radius: 3px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
    }
  }

  &-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 53px;
    height: 28px;
    color: #333;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
  }
}

.project-task-settings__row-wrapper.full-size {
  width: 272px;
}

.project-task-settings__input-with-unit-wrapper {
  display: flex;
  align-items: center;
}

.project-task-settings__row-wrapper {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.project-task-settings__row {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &.with-prefix {
    width: 94px;
  }

  & .full-width {
    width: 100%;
  }

  &.long {
    width: 100%;

    & label {
      width: 100%;
    }
  }
}

.project-task-settings__row-title {
  display: block;
  margin-bottom: 2px;
  color: #aaa;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
}

.project-task-settings__row-input {
  position: relative;
  width: 128px;

  &:not(:last-child) {
    margin-right: 12px;
  }

  &.full-width {
    width: 100%;
  }
}

.project-task-settings__checkbox-row {
  margin-top: 16px;
}

.project-task-settings__input {
  padding: 6px 35px 6px 8px;
}

.project-task-settings__options {
  margin-top: 20px;

  & > * {
    margin-bottom: 12px;
  }
}

.project-task-settings__input-unit,
.active-object__row-input-unit {
  position: absolute;
  right: 8px;
  bottom: 6px;
  color: #aaa;
  font-weight: 500;
  font-size: 10px;
  font-style: normal;
  line-height: 20px;
}

.project-task-settings__constrains-panel {
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 100%;
  margin-bottom: 16px;
  padding: 12px 8px 8px 8px;
  border: 1px solid #ededed;
  border-radius: 4px;
}

.project-task-settings__separator {
  width: 100%;
  height: 1px;
  margin-bottom: 12px;
  background: #ededed;
}

.project-left-col {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: calc(100vh - 56px);
  max-height: calc(100vh - 56px);
  border-right: 1px solid #ededed;
}

.project-left-col-bottom {
  display: flex;
  justify-content: space-between;
  padding: 12px 14px 12px 16px;
  border-top: 1px solid #ededed;

  &.__handlers {
    display: flex;
    align-items: center;
  }
}

.project-validation-title {
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;

  &.success {
    color: #29af62;
  }

  &.error {
    color: #fa3c00;
  }
}

.project-left-col-bottom__button {
  position: relative;
  cursor: pointer;

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.25s ease;
    content: '';
  }

  &:hover svg path {
    fill: #333;
  }

  &.active {
    & svg path {
      fill: #333;
    }

    &::after {
      background: #f5f5f5;
    }

    &:hover {
      &::after {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #f5f5f5;
      }
    }
  }

  &:not(:last-child) {
    margin-right: 12px;
  }
}

.project-issues {
  position: absolute;
  right: 311px;
  bottom: 44px;
  left: 201px;
  z-index: 9;
  height: 240px;
  padding-bottom: 24px;
  background-color: #fff;
  border-top: 1px solid #ededed;
  border-right: 1px solid #ededed;

  &__top-row {
    padding: 16px;
    border-bottom: 1px solid #ededed;
  }

  &__title {
    color: #333;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
  }
}

.project-issues__container {
  max-height: 191px;
  overflow: auto;
}

.project-issues__issue {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &-date {
    margin-right: 16px;
    color: #aaa;
    font-size: 11px;
    line-height: 16px;
  }

  &-text {
    margin-left: 8px;
    color: #333;
    font-size: 11px;
    line-height: 16px;
  }

  &-place {
    padding: 2px 4px;
    color: #333;
    font-weight: 600;
    background-color: #f7f7f7;
    border-radius: 4px;
  }
}

.project-issues__validation-section {
  margin: 0 16px;
  padding: 16px 0;

  &:not(:last-child) {
    position: relative;
    border-bottom: 1px solid #ededed;
  }
}

.active-object__top-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &.source-state-row {
    justify-content: space-between;
  }
}

.active-object__title {
  display: flex;
  gap: 4px;
  align-items: center;
  color: #333;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.active-object__subtitle {
  color: #333;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;

  &.limitations {
    margin-top: 20px;
    margin-bottom: 4px;
  }

  &.well {
    margin-top: 20px;
    margin-bottom: 4px;
  }
}

.active-object__row {
  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &.points-row {
    position: relative;
  }
}

.active-object__status {
  margin-left: 12px;
  padding: 4px 8px;
  color: #999;
  font-weight: bold;
  font-size: 8px;
  line-height: 10px;
  text-transform: uppercase;
  background-color: #f0f0f0;
  border-radius: 2px;

  &.progress {
    color: #29af62;
    background: rgba(41, 175, 98, 0.12);
  }

  &:disabled {
    color: #999;
    background-color: white;
    border: 1px solid #ededed;
  }
}

.active-object__row-input-wrapper {
  &.wide {
    width: 100%;
  }

  &.full-size {
    width: 272px;
  }

  &.well-model-param {
    position: relative;
    z-index: 100;
    width: 174px;
    margin-top: 20px;

    &.long {
      width: 236px;
    }
  }
}

.active-object__row-input {
  display: block;

  &.long {
    max-width: 272px;
  }

  &.short {
    min-width: 128px;
    max-width: 128px;
  }

  &.extra-short {
    max-width: 88px;
  }

  &.with-prefix {
    min-width: 94px;
    max-width: 94px;
  }

  &.with-unit {
    padding-right: 30px;
  }

  &.with-long-unit {
    padding-right: 64px;
  }

  &.left {
    margin-right: 16px;
  }
}

.active-object__row-input-prefix {
  width: 32px;
  height: 32px;
  margin-right: 2px;
  padding: 6px 0;
  color: #aaa;
  font-weight: 500;
  font-size: 10px;
  font-style: normal;
  line-height: 20px;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid #ededed;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  &.start {
    background-image: url(../../images/Project/ray-start-arrow.svg);
  }

  &.end {
    background-image: url(../../images/Project/ray-end.svg);
  }
}

.active-object__input-with-unit-wrapper {
  position: relative;
  display: flex;

  &.left {
    margin-right: 16px;
  }

  &.short {
    width: 128px;
  }

  &.extra-short {
    width: 88px;
  }
}

.index {
  position: absolute;
  top: -5px;
  right: -6px;
  font-size: 10px;

  &.small {
    top: -6px;
    right: -5px;
    font-size: 8px;
  }
}

.active-object__linked-icon {
  width: 10px;
  height: 32px;
  margin: 0 3px;
}

.active-object__add-point-btn {
  position: relative;
  margin: 16px 0 12px 0;
  padding-left: 20px;
  color: #333;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;

  &:hover {
    color: #00aeef;
  }

  &:active {
    color: #08a0d8;
  }
}

.active-object__add-icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.active-object__delete-point-btn {
  position: absolute;
  right: -16px;
  bottom: 8px;
}

.all-segments__aspo-icon,
.all-segments__reseted-icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.all-segments__progress-icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  animation: spin 4s linear infinite;

  & path {
    fill: #815df6;
  }
}

.aspo-subtitle {
  width: 272px;
}

.active-object__reset-aspo-btn {
  display: flex;
  align-items: center;
  margin-top: 12px;
  color: #333;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;

  &:disabled {
    cursor: default;

    &:not(.resetingAspo) {
      color: #aaa;
    }

    & .all-segments__aspo-icon path {
      fill: #aaa;
    }
  }

  &:not(:disabled):hover {
    color: #815df6;
  }

  &:not(:disabled):active {
    color: #7454dd;

    & .project-results-panel__aspo-icon path {
      fill: #7454dd;
    }
  }
}

.metal-consumption-row:not(:last-child) {
  margin-bottom: 0;
}

.metal-consumption-row__button,
.active-object__well-model-button {
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 8px;
  border-radius: 50%;

  &.active {
    background: #f5f5f5;

    & .metal-consumption-row__icon path,
    .well-model-button-icon path {
      fill: #333;
    }

    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #f5f5f5;
    }
  }
}

.metal-consumption-row__icon,
.well-model-button-icon {
  position: absolute;
  top: 50%;
  bottom: 50%;
  transform: translateX(-50%) translateY(-50%);

  &:hover path {
    fill: #333;
  }
}

.source-state__container {
  display: flex;
}

.flex-end {
  align-items: flex-end;
}
