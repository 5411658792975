.ag-table__status-component {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 18px;
  font-weight: 700;
  font-size: 8px;
  line-height: 10px;
  //text-align: center;
  border-radius: 2px;

  &.working {
    color: #29af62;
    background-color: #e5f5ec;
  }

  &.stopped {
    color: #aaa;
    background-color: #f5f5f5;
  }
}
